import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <div className="bg-red">
      <div className="z-20 flex flex-col items-center px-5 py-12 mx-auto lg:py-20">
        <div className="grid items-center gap-8 md:grid-cols-2">
          {/* Left Section */}
          <div className="text-left text-white">
            <div className="mb-10">
              <h1 className="text-3xl font-bold tracking-wide text-center sm:text-5xl animate-fade-in delay-1s lg:text-left">
                MEERSKANTFEESTEN
              </h1>
            </div>
            <div className="max-w-2xl">
              <div className="flex flex-col items-start justify-between mt-8">
                <p className="text-lg leading-relaxed text-center sm:text-xl xl:text-left animate-fade-in delay-3s">
                  De Meerskantfeesten is een jaarlijks terugkerend evenement in
                  Zingem. Het is een weekend vol plezier en vertier voor jong en
                  oud. Bekijk het programma van de huidige editie of herbeleef
                  de vorige edities.
                </p>
              </div>
              <div className="flex flex-col justify-around pt-10 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 xl:justify-start">
                <Link
                  to="/programma"
                  className="flex items-center text-white bg-[var(--darkred)] rounded-full px-6 py-3 sm:px-8 md:px-10 md:text-lg border border-[var(--darkred)] sm:text-base font-medium hover:bg-transparent transition-all hover:text-[var(--darkred)]">
                  Programma
                  <div className="inline-block ml-2">
                    <BsArrowUpRight className="inline-block my-0 text-lg" />
                  </div>
                </Link>
                <Link
                  to="/contact"
                  className="flex items-center text-white bg-[var(--darkred)] rounded-full px-6 py-3 sm:px-8 md:px-10 md:text-lg border border-[var(--darkred)] sm:text-base font-medium hover:bg-transparent transition-all hover:text-[var(--darkred)]">
                  Contacteer
                  <div className="inline-block ml-2">
                    <BsArrowUpRight className="inline-block my-0 text-lg" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="text-center text-white">
            <div className="flex justify-center animate-fade-in delay-5s">
              <img
                src="./img/msk-org-rev.svg"
                alt="Meerskantfeesten Logo"
                className="h-[250px] w-[250px] sm:h-[300px] sm:w-[350px] lg:h-[400px] lg:w-[400px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
