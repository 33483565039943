import React, { Component } from "react";

export default class PartnerSlider extends Component {
  render() {
    return (
      <div className="overflow-hidden text-left text-white bg-red">
        <div className="mb-10">
          <h1 className="tracking-wide text-center text-1xl md:text-2xl">
            Zonder onze partners zou dit niet mogelijk zijn:
          </h1>
        </div>
        <div className="logos">
          <div className="logos-slide">
            <img src="./img/partners/adidas.png" alt="" />
            <img src="./img/partners/facebook.png" alt="" />
            <img src="./img/partners/google.png" alt="" />
            <img src="./img/partners/instagram.png" alt="" />
            <img src="./img/partners/nike.png" alt="" />
            <img src="./img/partners/twitter.png" alt="" />
            <img src="./img/partners/uber.png" alt="" />
            <img src="./img/partners/youtube.png" alt="" />
          </div>
          <div className="logos-slide">
            <img src="./img/partners/adidas.png" alt="" />
            <img src="./img/partners/facebook.png" alt="" />
            <img src="./img/partners/google.png" alt="" />
            <img src="./img/partners/instagram.png" alt="" />
            <img src="./img/partners/nike.png" alt="" />
            <img src="./img/partners/twitter.png" alt="" />
            <img src="./img/partners/uber.png" alt="" />
            <img src="./img/partners/youtube.png" alt="" />
          </div>
        </div>
      </div>
    );
  }
}
