import React from "react";
import { FaHeart } from "react-icons/fa";

const FooterSection = () => {
  return (
    <footer className="bg-[#1b1b1b] text-white py-5">
      <div className="flex flex-wrap justify-around md:justify-between">
        <div className="flex-[1_1_150px] flex justify-center items-center mb-5 md:mb-0">
          <a href="/">
            <img
              src="./img/msk-org.svg"
              className="w-[150px] h-auto md:w-[200px]"
              alt="Meerskantfeesten Logo"
            />
          </a>
        </div>

        <div className="flex-[1_1_150px] m-5 text-center md:text-left">
          <h3 className="mx-0 my-[5px] pb-[5px] border-b-white border-b border-solid last:border-b-none">
            MEERSKANTFEESTEN
          </h3>
          <ul className="p-0 list-none">
            <li>Feestlocatie</li>
            <li>Wittelstraat - Speelstraat 9750 Zingem</li>
          </ul>
        </div>

        <div className="flex-[1_1_150px] m-5 text-center md:text-left">
          <h3 className="mx-0 my-[5px] pb-[5px] border-b-white border-b border-solid last:border-b-none">
            CONTACT
          </h3>
          <ul>
            <li>
              <a href="/">Bestuur</a>
            </li>
            <li>
              <a href="/">Partners</a>
            </li>
            <li>
              <a href="/">Vrijwilliger worden</a>
            </li>
          </ul>
        </div>

        <div className="flex-[1_1_150px] m-5 text-center md:text-left">
          <h3 className="mx-0 my-[5px] pb-[5px] border-b-white border-b border-solid last:border-b-none">
            VOLG ONS
          </h3>
          <ul>
            <li>
              <a href="/">Instagram</a>
            </li>
            <li>
              <a href="/">Facebook</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="pt-5 text-sm text-center">
        <p className="text-[#ffb400]">
          Copyright{" "}
          <strong>
            <span>Flanders Visual Studio</span>
          </strong>
          . All Rights Reserved.
        </p>
        <p>
          Designed by{" "}
          <a href="https://www.flandersvisualstudio.be">Michael De Ruyck</a>{" "}
          with love <FaHeart className="inline-block text-red-500" />
        </p>
      </div>
    </footer>
  );
};

export default FooterSection;
