import React from "react";

const organisatoren = [
  {
    naam: "Gerrit Depaepe",
    functie: "Organisator",
    foto: "./img/medewerkers/gerrit.png",
    email: "gerrit@meerskantfeesten.be",
  },
  {
    naam: "Kristof Vanmeirhaeghe",
    functie: "Organisator",
    foto: "./img/medewerkers/kristof.png",
    email: "kristof@meerskantfeesten.be",
  },
  {
    naam: "Geert Schiettecatte",
    functie: "Organisator",
    foto: "./img/medewerkers/geert.png",
    email: "geert@meerskantfeesten.be",
  },
  // Voeg hier meer medewerkers toe
];

const medewerkers = [
  {
    naam: "Gerrit Depaepe",
    functie: "Organisator",
    foto: "./img/medewerkers/gerrit.png",
  },
  {
    naam: "Kristof Vanmeirhaeghe",
    functie: "Organisator",
    foto: "./img/medewerkers/kristof.png",
  },
  {
    naam: "Geert Schiettecatte",
    functie: "Organisator",
    foto: "./img/medewerkers/geert.png",
  },
  // Voeg hier meer medewerkers toe
];

const Medewerkers = () => {
  return (
    <div className="container p-4 mx-auto">
      <h1 className="mb-4 text-3xl font-light text-center">Organisatoren</h1>
      <div className="flex flex-wrap -mx-4">
        {organisatoren.map((organisatoren, index) => (
          <div key={index} className="w-full px-4 mb-8 md:w-1/3">
            <div className="text-center">
              <img
                src={organisatoren.foto}
                alt={organisatoren.naam}
                className="object-cover w-32 h-32 mx-auto mb-4 rounded-full"
              />
              <h2 className="text-2xl font-light">{organisatoren.naam}</h2>
              <p className="text-xl text-[#1b1b1b]">{organisatoren.functie}</p>
              <p className="text-lg text-[#1b1b1b]">{organisatoren.email}</p>
            </div>
          </div>
        ))}
      </div>
      <h1 className="mb-4 text-3xl font-light text-center">Medewerkers</h1>
      <div className="flex flex-wrap -mx-4">
        {medewerkers.map((medewerker, index) => (
          <div key={index} className="w-full px-4 mb-8 md:w-1/3">
            <div className="text-center">
              <img
                src={medewerker.foto}
                alt={medewerker.naam}
                className="object-cover w-32 h-32 mx-auto mb-4 rounded-full"
              />
              <h2 className="text-xl font-light">{medewerker.naam}</h2>
              <p className="text-[#1b1b1b]">{medewerker.functie}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Medewerkers;
