import React, { Component } from "react";
import Countdown from "./Countdown";
export default class CountdownSection extends Component {
  render() {
    return (
      <div className="flex items-center justify-center py-8 bg-red">
        <Countdown targetDate="2025-08-01T00:00:00" />
      </div>
    );
  }
}
