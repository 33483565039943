// Auth.js
import React, { useState } from "react";

const Auth = ({ onAuthSuccess }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const predefinedCode = "MSK2025"; // Replace with your custom code

    if (code === predefinedCode) {
      onAuthSuccess();
    } else {
      setError("Invalid code. Please try again.");
    }
  };

  return (
    <div className="relative bg-[#121212] min-h-screen flex items-center justify-center">
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <img
          src="/img/bg-lines.png"
          alt="background"
          className="object-cover w-full h-full"
          quality={100}
        />
      </div>
      <div className=" relative z-20 flex flex-col items-center w-full max-w-sm p-8 bg-[#1b1b1b] rounded-xl shadow-xl">
        <a href="https://www.flandersvisualstudio.be">
          <img
            src="./img/fvs_langwit.png"
            alt="logo"
            width={150}
            height={150}
            className="mb-4"
          />
        </a>
        <h2 className="mb-4 text-2xl font-bold text-center text-[var(--white)] barlow">
          FVS Authentication
        </h2>

        <form onSubmit={handleSubmit} className="w-full">
          <label className="block mb-4">
            <span className="block mb-2 text-sm font-bold text-[var(--white)] barlow">
              Enter Authentication Code:
            </span>
            <input
              type="password"
              value={code}
              placeholder="Enter Authentication code"
              onChange={(e) => setCode(e.target.value)}
              className="w-full px-3 py-2 leading-tight text-[#1b1b1b] border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            />
          </label>
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline barlow">
            Submit
          </button>
        </form>
        {error && <p className="mt-4 italic text-red-500 text-md">{error}</p>}
      </div>
    </div>
  );
};

export default Auth;
