import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./index.css";
import HeaderSection from "./components/HeaderSection";
import HeroSection from "./components/HeroSection";
import FooterSection from "./components/FooterSection";
import PartnerSlider from "./components/PartnerSlider";
import CountdownSection from "./components/CountdownSection";
import Programma from "./pages/programma.js";
import OverOns from "./pages/overons.js";
import Medewerkers from "./pages/medewerkers.js";
import Herbeleef from "./pages/herbeleef.js";
import Eetfestijn from "./pages/eetfestijn.js";
import Auth from "./Auth";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
  };

  if (!isAuthenticated) {
    return <Auth onAuthSuccess={handleAuthSuccess} />;
  }
  return (
    <Router>
      <HeaderSection />
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/programma" element={<Programma />} />
        <Route path="/partners" element={<PartnerSlider />} />
        <Route path="/medewerkers" element={<Medewerkers />} />
        <Route path="/over-ons" element={<OverOns />} />
        <Route path="/contact" element={<PartnerSlider />} />
        <Route path="/herbeleef" element={<Herbeleef />} />
        <Route path="/eetfestijn" element={<Eetfestijn />} />
      </Routes>
      <CountdownSection />
      <PartnerSlider />
      <FooterSection />
    </Router>
  );
}

export default App;
