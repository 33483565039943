import React, { useState, useEffect } from "react";

const Herbeleef = () => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const banners = {
    banner1: [
      "./img/feest/vv.png",
      "./img/feest/vrijv1.jpg",
      "./img/feest/vrijv2.jpg",
      "./img/feest/vrijv3.jpg",
      "./img/feest/vv.png",
      "./img/feest/vrij1.jpg",
      "./img/feest/vrij2.jpg",
      "./img/feest/vrij3.jpg",
      "./img/feest/vv.png",
      "./img/feest/vrij4.jpg",
      "./img/feest/vrij5.jpg",
      "./img/feest/vrij6.jpg",
    ],
    banner2: [
      "./img/feest/kn.png",
      "./img/feest/kn1.jpg",
      "./img/feest/kn2.jpg",
      "./img/feest/kn3.jpg",
      "./img/feest/eet.png",
      "./img/feest/kn4.jpg",
      "./img/feest/kn5.jpg",
      "./img/feest/kn6.jpg",
      "./img/feest/kn.png",
      "./img/feest/kn7.jpg",
      "./img/feest/kn8.jpg",
      "./img/feest/kn9.jpg",
    ],
    banner3: [
      "./img/feest/za.png",
      "./img/feest/za1.jpg",
      "./img/feest/za2.jpg",
      "./img/feest/za3.jpg",
      "./img/feest/za.png",
      "./img/feest/za4.jpg",
      "./img/feest/eet.png",
      "./img/feest/za5.jpg",
      "./img/feest/za.png",
      "./img/feest/za6.jpg",
      "./img/feest/eet2.png",
      "./img/feest/za6.jpg",
    ],
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex(
        (prevIndex) => (prevIndex + 1) % banners.banner1.length
      );
    }, 3000); // Change banner every 3 seconds

    return () => clearInterval(interval);
  }, [banners.banner1.length]);

  return (
    <div className="container p-6 mx-auto">
      <h1 className="p-6 mb-8 text-2xl font-bold tracking-wider text-center text-white rounded-lg shadow-lg md:text-4xl bg-red">
        Programma
      </h1>
      <p className="mb-8 text-lg text-center text-gray-700">
        Hier is het programma voor de Meerskantfeesten.
      </p>
      <div className="flex items-center justify-center mb-8">
        <div className="text-2xl font-medium text-gray-800">
          Gratis toegang voor iedereen!
        </div>
      </div>
      <div className="mb-12 border-t-2 border-red-800"></div>
      <section className="py-8">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4">
            {/* Event Card */}
            <div className="w-full p-5 mb-8 md:w-1/3">
              <div className="overflow-hidden border-2 border-[var(--red)] rounded-lg shadow-lg">
                <div className="p-4 text-center text-white bg-[var(--red)]">
                  <p className="text-lg font-medium">Vrijdag 1 Augustus 2025</p>
                </div>
                <img
                  src={banners.banner1[currentBannerIndex]}
                  alt="Event banner"
                  className="object-cover w-full h-72"
                />
                <div className="p-4 text-gray-700 programma">
                  <ul className="space-y-2">
                    <li className="flex justify-center">
                      <span>Uur</span>
                      <span>Activiteit</span>
                    </li>
                    <li className="flex justify-between">
                      <span>14u30</span>
                      <span>Wielerwedstrijd</span>
                    </li>
                    <li className="flex justify-between">
                      <span>19u00</span>
                      <span>Schietstuiveren</span>
                    </li>
                    <li className="flex justify-between">
                      <span>22u00</span>
                      <span>FOUTE MEERSKANTPARTY</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Event Card */}
            <div className="w-full p-5 mb-8 md:w-1/3">
              <div className="overflow-hidden border-2 border-[var(--red)] rounded-lg shadow-lg bg-gradient-to-r from-red-800 via-red-600 to-red-800">
                <div className="p-4 text-center text-white bg-[var(--red)]">
                  <p className="text-lg font-medium">
                    Zaterdag 2 Augustus 2025
                  </p>
                </div>
                <img
                  src={banners.banner2[currentBannerIndex]}
                  alt="Event banner"
                  className="object-cover w-full h-72"
                />
                <div className="p-4 text-gray-700 bg-gray-100 programma">
                  <ul className="space-y-2">
                    <li className="flex justify-between">
                      <span>Uur</span>
                      <span>Activiteit</span>
                    </li>
                    <li className="flex justify-between">
                      <span>15u00</span>
                      <span>Kinderanimatieshow</span>
                    </li>
                    <li className="flex justify-between">
                      <span>15u00</span>
                      <span>Kinderdisco en Grime</span>
                    </li>
                    <li className="flex justify-between">
                      <span>15u00</span>
                      <span>Springkastelen en kermiskraam</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Event Card */}
            <div className="w-full p-5 mb-8 md:w-1/3">
              <div className="overflow-hidden border-2 border-[var(--red)] rounded-lg shadow-lg bg-gradient-to-r from-red-800 via-red-600 to-red-800">
                <div className="p-4 text-center text-white bg-[var(--red)]">
                  <p className="text-lg font-medium">
                    Zaterdag 2 Augustus 2025
                  </p>
                </div>
                <img
                  src={banners.banner3[currentBannerIndex]}
                  alt="Event banner"
                  className="object-cover w-full h-72"
                />
                <div className="p-4 text-gray-700 bg-gray-100 programma">
                  <ul className="space-y-2">
                    <li className="flex justify-between">
                      <span>Uur</span>
                      <span>Activiteit</span>
                    </li>
                    <li className="flex justify-between">
                      <span>18u30</span>
                      <span>Groot Eetfestijn</span>
                    </li>
                    <li className="flex justify-between">
                      <span>22u00</span>
                      <span>Optreden - Artiest</span>
                    </li>
                    <li className="flex justify-between">
                      <span>23u00</span>
                      <span>TROPICAL SUMMERNIGHT PARTY</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Herbeleef;
