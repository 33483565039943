import React, { useState, useEffect } from "react";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dagen: Math.floor(difference / (1000 * 60 * 60 * 24)),
        uren: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minuten: Math.floor((difference / 1000 / 60) % 60),
        seconden: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (Object.keys(newTimeLeft).length === 0) {
        setIsTimeUp(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="mb-6 text-2xl tracking-wider text-center text-white uppercase caption">
        Het meerskant weekend start binnen:
      </h1>
      {isTimeUp ? (
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-4xl text-[#1b1b1b] animate-pulse">
            Het feest is nu bezig!
          </h2>
          <p>Feestlocatie: Wittelstraat - Speelstraat 9750 Zingem</p>
        </div>
      ) : (
        <div className="grid justify-around max-w-lg grid-cols-4 gap-4 countdown">
          {Object.entries(timeLeft).map(([interval, value]) => (
            <div key={interval} className="text-center time-wrapper">
              <div className="relative mb-4 overflow-hidden rounded-lg shadow-lg time-content bg-very-dark-blue">
                <div className="relative flex items-center justify-center h-20 time bg-[var(--darkred)]">
                  <span className="z-10 text-3xl text-white xl:text-4xl">
                    {value}
                  </span>
                </div>
              </div>
              <p className="text-xs tracking-widest uppercase metric text-light-blue">
                {interval}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Countdown;
