import React from "react";

const OverOns = () => {
  return (
    <div className="max-w-4xl p-6 mx-auto">
      <div className="mb-10">
        <h1 className="p-4 mb-6 font-bold tracking-wide text-center rounded-lg shadow-lg text-1xl md:text-2xl bg-gradient-to-r from-red-800 via-red-600 to-red-800">
          OVER ONS
        </h1>
      </div>
      <div className="p-6 space-y-6 text-sm font-light text-white rounded-lg shadow-lg sm:text-xl bg-gradient-to-r from-red-800 via-red-600 to-red-800">
        <p className="leading-relaxed">
          De Meerskantfeesten is een jaarlijks evenement dat plaatsvindt in de
          zomer in de Zingemse Meerskant. Het is een evenement waarbij de
          bewoners van de Meerskant samen komen om te genieten van
          muziekoptredens, eten en drinken.
        </p>
        <p className="leading-relaxed">
          Het evenement wordt georganiseerd door een groep vrijwilligers die
          zich inzetten om er een geslaagd feest van te maken. Het doel van de
          Meerskantfeesten is om de saamhorigheid in de buurt te vergroten en om
          de bewoners een leuke dag te bezorgen.
        </p>
        <p className="leading-relaxed">
          Het evenement is gratis toegankelijk voor iedereen en wordt mede
          mogelijk gemaakt door de steun van lokale ondernemers en sponsoren. Al
          vanaf 2000 organiseren wij de Meerskantfeesten. Inmiddels is het
          uitgegroeid tot een groot evenement waar jaarlijks honderden mensen op
          afkomen.
        </p>
        <p className="leading-relaxed">
          Het programma van de Meerskantfeesten bestaat uit verschillende
          activiteiten voor jong en oud. Zo zijn er optredens van bekende
          artiesten, wielerwedstijden, foute party, cava loop.
        </p>
        <p className="leading-relaxed">
          Wij hopen u te mogen verwelkomen op de volgende editie!{" "}
        </p>
      </div>
    </div>
  );
};

export default OverOns;
