import React from "react";

const Eetfestijn = () => {
  return (
    <div className="container p-6 mx-auto">
      <h1 className="p-6 mb-8 text-2xl font-semibold tracking-wider text-center text-white rounded-lg shadow-lg md:text-4xl bg-red">
        Eetfestijn
      </h1>
      <p className="mb-8 text-lg text-gray-700">
        Het eetfestijn vindt plaats op zaterdag 2 augustus 2025. Kom genieten
        van een heerlijke maaltijd en een gezellige sfeer. U kan kiezen uit
        verschillende gerechten en dranken. Er is voor ieder wat wils! Geniet
        van een gratis optreden van een vlaams vedet na het eetfestijn!
      </p>
      <div className="mb-12 border-t-2 border-red-800"></div>
      <section className="py-8">
        <div className="container mx-auto">
          <div className="flex flex-wrap p-6 -mx-4">
            {/* Event Card */}
            <div className="w-full">
              <div className="overflow-hidden border-2 border-[var(--red)] rounded-lg shadow-lg">
                <div className="p-4 text-center text-white bg-[var(--red)]">
                  <p className="text-lg font-medium">Kaarten</p>
                </div>
                <div className="p-4 text-gray-700 programma">
                  <ul className="space-y-2">
                    <li className="grid grid-cols-4 gap-4">
                      <span>Gerecht</span>
                      <span className="text-center">-6 Jaar</span>
                      <span className="text-center">-12 Jaar</span>
                      <span className="text-center">Volwassenen</span>
                    </li>
                    <li className="grid grid-cols-4 gap-4">
                      <span>
                        Beenham met champignonsaus of provencaalse saus
                      </span>
                      <span className="text-center">€ 8</span>
                      <span className="text-center">€ 12</span>
                      <span className="text-center">€ 20</span>
                    </li>
                    <li className="grid grid-cols-4 gap-4">
                      <span>Balletjes in tomatensaus</span>
                      <span className="text-center">€ 8</span>
                      <span className="text-center">€ 12</span>
                      <span className="text-center">€ 20</span>
                    </li>
                    <li className="grid grid-cols-4 gap-4">
                      <span>Veggie lasange</span>
                      <span className="text-center">€ 8</span>
                      <span className="text-center">€ 12</span>
                      <span className="text-center">€ 20</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Eetfestijn;
