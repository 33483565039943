import { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { MdAttachEmail } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import {
  BsList,
  BsChatLeftTextFill,
  BsX,
  BsPeopleFill,
  BsBuildingFillCheck,
  BsHouseDoorFill,
  BsBookFill,
  BsCalendarCheckFill,
  BsRewindBtnFill,
  BsEnvelopeFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-[#1b1b1b] text-white">
      <div className="flex items-center justify-between p-4 mx-auto">
        <div className="flex items-center">
          <a href="/">
            <h1 className="text-2xl font-bold text-white md:text-3xl">
              MEERSKANTFEESTEN
            </h1>
          </a>
        </div>
        <nav className="flex-col hidden lg:flex">
          <ul className="flex space-x-6 border-b border-white top-header">
            <li>
              <p
                href="/"
                className="flex items-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                Volgende editie: 1-2 Aug 2025
              </p>
            </li>
            <li>
              <Link
                to="/over-ons"
                className="flex items-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsChatLeftTextFill className="mr-2" />
                Over ons
              </Link>
            </li>
            <li>
              <Link
                to="/medewerkers"
                className="flex items-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsPeopleFill className="mr-2" /> Medewerkers
              </Link>
            </li>
            <li>
              <a
                href="/partners"
                className="flex items-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsBuildingFillCheck className="mr-2" /> Partners
              </a>
            </li>
          </ul>
          <ul className="flex justify-between down-header">
            <li>
              <Link
                to="/"
                onClick={toggleMenu}
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsHouseDoorFill className="mr-2" /> Home
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/programma"
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsBookFill className="mr-2" /> Programma
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/eetfestijn"
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsCalendarCheckFill className="mr-2" /> Eetfestijn
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/herbeleef"
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsRewindBtnFill className="mr-2" /> Herbeleef
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/contact"
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsEnvelopeFill className="mr-2" /> Contact
              </Link>
            </li>
          </ul>
        </nav>

        <div className="lg:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={toggleMenu}>
            {isMenuOpen ? <BsX size={30} /> : <BsList size={30} />}
          </button>
        </div>
      </div>
      <div
        className={`fixed top-0 bottom-0 right-0 w-full z-[9991] p-5 bg-[#1b1b1b] transition-transform duration-[0.7s] ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } lg:hidden`}>
        <div className="flex justify-end">
          <button
            className="text-xl text-white border-none cursor-pointer"
            onClick={toggleMenu}>
            <IoClose className="text-2xl" />
          </button>
        </div>
        <div className="flex justify-center">
          <a href="/">
            <img
              src="./img/msk-org-rev.svg"
              alt="logo"
              width={150}
              height={150}
            />
          </a>
        </div>

        <nav className="flex justify-center" id="navbar">
          <ul className="space-y-2 text-center">
            <li>
              <p
                href="/"
                className="flex items-center border-transparent justify-centerborder-b-2 md:text-lg hover:text-red hover:border-red">
                Volgende editie: 1-2 Aug 2025
              </p>
            </li>
            <li>
              <Link
                to="/"
                onClick={toggleMenu}
                className="flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsHouseDoorFill className="mr-2" /> Home
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/programma"
                className="flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsBookFill className="mr-2" /> Programma
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/eetfestijn"
                className="flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsCalendarCheckFill className="mr-2" /> Eetfestijn
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/herbeleef"
                className="flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsRewindBtnFill className="mr-2" /> Herbeleef
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/contact"
                className="flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsEnvelopeFill className="mr-2" /> Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/over-ons"
                className="flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsChatLeftTextFill className="mr-2" />
                Over ons
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/medewerkers"
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsPeopleFill className="mr-2" /> Medewerkers
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/partners"
                className="flex items-center justify-center pb-1 text-sm text-white border-b-2 border-transparent md:text-lg hover:text-red hover:border-red">
                <BsBuildingFillCheck className="mr-2" /> Partners
              </Link>
            </li>
          </ul>
        </nav>
        <h2 className="m-2 text-2xl font-bold text-center text-[#c42217] border-b-2 border-gray-200">
          Contact
        </h2>
        <ul className="flex flex-col gap-4 mb-5">
          <li className="flex items-center justify-center gap-4 text-lg">
            <span className="flex items-center justify-center w-12 h-12 bg-[#c42217] rounded-full">
              <MdAttachEmail className="text-[var(--dark)]" />
            </span>
            <span className="text-sm">
              <small className="block text-sm font-medium text-white">
                U kunt ons bereiken via email:
              </small>
              info@meerskantfeesten.be
            </span>
          </li>
        </ul>

        <div className="py-5 text-center text-xs text-[var(--black)]">
          <p className="no-underline bold">
            Copyright{" "}
            <strong>
              <span className="text-red-500">Flanders Visual Studio</span>
            </strong>{" "}
            All Rights Reserved.
          </p>
          <p>
            Designed by{" "}
            <a href="https://www.flandersvisualstudio.be">Michael De Ruyck</a>{" "}
            with love <FaHeart className="inline-block text-red-500" />
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
